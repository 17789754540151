var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "contenedor-titulo" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "botones" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus", plain: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              },
              [_vm._v("Nuevo Tipo Calibre")]
            )
          ],
          1
        )
      ]),
      _c("el-card", { attrs: { "body-style": { padding: "20px" } } }, [
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Nombre" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: { textContent: _vm._s(props.row.nombre) }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Editar", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "warning",
                                size: "small",
                                plain: "",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.modalModificar.abrir(
                                    props.row.id
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-edit" })]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Borrar", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                size: "small",
                                plain: "",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.eliminar(props.row.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-delete" })]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titulo" }, [
      _c("h3", [
        _c("i", { staticClass: "el-icon-s-operation" }),
        _vm._v("Tipos de Calibre\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }